import _ from 'lodash';
import api from './api';

const getInitProps = async (ctx) => {
  const { data } = await api.get('/api/v1/appLaunch', {});
  return {
    ...data,
  };
};

export default getInitProps;
