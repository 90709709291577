import forEach from 'lodash/forEach';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSearchParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import QueryString from 'qs';

import Head from 'components/Head';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Menubar from 'components/Menubar';
import MobileTabBar from 'components/MobileTabBar';
import CommentPopup from 'components/CommentPopup';
import Footer from 'components/Footer';

import api from 'utils/api';
import getInitProps from 'utils/getInitProps';
import { httpCall } from 'functions/httpAction';
import { fetchAppLaunch } from 'functions/appLaunch';

export default function Custom404() {
  const router = useRouter();

  //from AppLaunch API
  const [arrNotificationState, setArrNotificationState] = useState([]);
  const [arrStoresState, setArrStoresState] = useState(null);
  const [arrProductOriginsState, setArrProductOriginsState] = useState(null);
  const [arrCategoryState, setArrCategoryState] = useState([]);
  const [arrSearchSkinProblemState, setArrSearchSkinProblemState] = useState(
    []
  );
  const [arrIngredientSafetiesState, setArrIngredientSafetiesState] = useState(
    []
  );
  const [arrFactorTypesState, setArrFactorTypesState] = useState([]);

  const [categoryCountState, setCategoryCountState] = useState(0);
  const [productCountState, setProductCountState] = useState(0);
  const [reviewCountState, setReviewCountState] = useState(0);

  const [isCommentModalShow, setIsCommentModalShow] = useState(false);

  function appLaunch() {
    fetchAppLaunch().then(([success, error, data]) => {
      // console.log(data);

      if (data.notifications) {
        setArrNotificationState(data.notifications);
      }

      if (data.stores) {
        setArrStoresState(data.stores);
      }

      if (data.product_origins) {
        setArrProductOriginsState(data.product_origins);
      }

      if (data.category_tree) {
        setArrCategoryState(data.category_tree);
      }

      if (data.search_skin_problem_keywords) {
        setArrSearchSkinProblemState(data.search_skin_problem_keywords);
      }

      if (data.ingredient_safeties) {
        setArrIngredientSafetiesState(data.ingredient_safeties);
      }

      if (data.factor_types) {
        setArrFactorTypesState(data.factor_types);
      }

      if (data.content_counts) {
        setCategoryCountState(data.content_counts.category_count);
        setProductCountState(data.content_counts.product_count);
        setReviewCountState(data.content_counts.review_count);
      }
    });
  }

  useEffect(() => {
    appLaunch();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      router.push('/');
    }, 1000);
  }, []);

  return (
    <div>
      <Head />

      <main>
        <Menubar
          props={{
            homeExtend: false,
            notifications: arrNotificationState,
            sourceUI: '404',
          }}
        />

        <div
          className={
            'globals-centerContentsMargin relative' +
            (arrNotificationState?.length > 0 ? ' withNoti' : '')
          }
        >
          <MaxWidthContainer className="flex h-[calc(100svh-65px-64px)] w-full items-center justify-center px-4 lg:h-[calc(100svh-105px)]">
            <div>
              <div className="flex items-center justify-center">
                <img src="/svgs/404.svg" />
              </div>
              <div className="mt-[24px] text-center text-sm font-normal text-sorra-gray-900 lg:mt-[42px] lg:text-lg">
                對不起！我們找不到你想要的內容。
                <br />
                請返回首頁重新出發～
              </div>
              <div className="mt-[38px] text-center text-xl font-normal text-sorra-gray-900 lg:mt-[42px]">
                <button
                  className="h-[48px] w-full rounded-[8px] bg-sorra-pink text-base font-medium text-[#fff] lg:w-[300px]"
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  返回首頁
                </button>
              </div>
            </div>
          </MaxWidthContainer>
        </div>

        {/* <CommentPopup
          externalPopupShown={isCommentModalShow}
          externalProductID={null}
          externalProductBrandName={null}
          externalProductName={null}
          setExternalPopupShown={setIsCommentModalShow}
        /> */}

        <MobileTabBar props={{ highlighted: 'home' }} />
      </main>
    </div>
  );
}
